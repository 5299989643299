import React from 'react';
import styled from '@emotion/styled';
import type { Dayjs } from 'dayjs';
import formatLocation from 'components/publicWebsiteV2/util/formatLocation';
import { WeddingDateDisplayFormatEnum } from '../../../../../../util/websiteDateUtil';
import { DisplayTypeProps } from './props';
import { VerticalContainer, VerticalLocationContainer, To } from '../LocationDateModule.styles';

const LocationDateModuleVertical = ({
  className,
  city,
  state,
  websiteDate,
  headerFontStyles,
  bodyFontStyles,
  textAlignment,
}: DisplayTypeProps): JSX.Element | null => {
  const H3 = styled.h3`
    ${headerFontStyles}
  `;

  const renderDateFormat = (): React.ReactNode | null => {
    const renderDate = (d?: Dayjs) => (
      <H3>{`${d?.format('MMMM')} ${d?.format('D')}, ${d?.format('YYYY')}`}</H3>
    );

    if (websiteDate?.displayFormat === WeddingDateDisplayFormatEnum.CUSTOM) {
      return <div css={bodyFontStyles}>{websiteDate.customDateMessage}</div>;
    }
    if (websiteDate?.displayFormat === WeddingDateDisplayFormatEnum.RANGE) {
      const { weddingDateRangeStart, weddingDateRangeEnd } = websiteDate || {};
      const sameYear = weddingDateRangeStart?.get('year') === weddingDateRangeEnd?.get('year');
      const sameMonth =
        sameYear && weddingDateRangeStart?.get('month') === weddingDateRangeEnd?.get('month');

      if (sameMonth) {
        return (
          <H3>
            {weddingDateRangeStart?.format('MMMM')} {weddingDateRangeStart?.format('D')} -{' '}
            {weddingDateRangeEnd?.format('D')}, {weddingDateRangeEnd?.format('YYYY')}
          </H3>
        );
      }

      if (sameYear) {
        return (
          <H3>
            {weddingDateRangeStart?.format('MMMM')} {weddingDateRangeStart?.format('D')} -{' '}
            {weddingDateRangeEnd?.format('MMMM')} {weddingDateRangeEnd?.format('D')},{' '}
            {weddingDateRangeEnd?.format('YYYY')}
          </H3>
        );
      }

      return (
        <>
          {renderDate(weddingDateRangeStart)}
          <To>-</To>
          {renderDate(weddingDateRangeEnd)}
        </>
      );
    }
    if (websiteDate?.weddingDate) {
      return renderDate(websiteDate?.weddingDate);
    }
    return null;
  };

  return (
    <VerticalContainer className={className} textAlignment={textAlignment}>
      {renderDateFormat()}
      <VerticalLocationContainer css={bodyFontStyles}>
        {formatLocation(city, state)}
      </VerticalLocationContainer>
    </VerticalContainer>
  );
};

export default LocationDateModuleVertical;
