import styled from '@emotion/styled';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import { mapPseudoElementToCSS } from 'components/publicWebsiteV2/util/mappers';
import {
  Z_INDEX_TEXT_LAYER_KEY,
  Z_INDEX_MAP,
} from 'components/publicWebsiteV2/util/zIndexConstants';
import { css } from '@emotion/react';
import { NON_ADJUSTED_RANGE } from 'components/publicWebsiteV2/pageLayouts/SinglePageLayout/transformMediaQuery';

export const OuterContainer = styled.div<{ hideNames?: boolean }>`
  padding-top: 90px;
  padding-bottom: ${props => props.theme.SPACING.XXL};

  ${props => props.theme.MEDIA_QUERY.TABLET} {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    padding-top: ${props => props.theme.SPACING.LG};
    padding-bottom: ${props => props.theme.SPACING.LG};
  }
  width: 100%;

  ${({ hideNames }) =>
    hideNames
      ? `display: none;
        ${NON_ADJUSTED_RANGE} {
          display: block;
        }`
      : ''}
`;

export const Container = styled.div<{
  inFlowAsset?: WInFlowThemeComponentAssetSummaryView;
}>`
  width: 100%;
  text-align: center;
  position: relative;
  // overflow: auto;

  ${({ inFlowAsset }) => {
    if (!inFlowAsset) return '';
    const { orientation, before, after } = inFlowAsset;
    return css`
      display: flex;
      flex-direction: ${orientation ===
      (('HORIZONTAL' as unknown) as WInFlowThemeComponentAssetSummaryView.OrientationEnum)
        ? 'row'
        : 'column'};
      align-items: center;
      justify-content: center;
      :before {
        ${mapPseudoElementToCSS(before)}
        flex-shrink: 0;
      }
      :after {
        ${mapPseudoElementToCSS(after)}
        flex-shrink: 0;
      }
    `;
  }}
`;

export const Name = styled.div`
  display: flex;
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  max-width: 100%;
`;

export const Name1 = styled(Name)``;
export const Name2 = styled(Name)``;

export const And = styled.div`
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};

  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    margin: ${props => props.theme.SPACING.MD} 0;
  }
`;

const mapTextAlignmentToFlexAlignItemsProperty = (t: string): string => {
  if (t === 'LEFT') return 'flex-start';
  if (t === 'RIGHT') return 'flex-end';
  return 'center';
};

export const ContentContainerHorizontal = styled.div<{
  textAlignment: string;
}>`
  display: inline-flex;
  max-width: 100%;
  justify-content: ${({ textAlignment }) =>
    mapTextAlignmentToFlexAlignItemsProperty(textAlignment)};
  align-items: center;
  ${({ textAlignment }) => (textAlignment === 'CENTER' ? 'max-width: 800px' : 'width: 100%')};
  text-align: ${({ textAlignment }) => textAlignment.toLowerCase()};
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  ${Name1}, ${Name2} {
    flex-direction: column;
    ${({ textAlignment }) => (textAlignment === 'CENTER' ? 'width: 30%;' : '')};
  }
  ${Name1} {
    align-items: ${({ textAlignment }) => (textAlignment === 'LEFT' ? 'flex-start' : 'flex-end')};
    ${({ textAlignment }) => textAlignment === 'CENTER' && 'text-align: right;'}
  }
  ${Name2} {
    align-items: ${({ textAlignment }) => (textAlignment === 'RIGHT' ? 'flex-end' : 'flex-start')};
    ${({ textAlignment }) => textAlignment === 'CENTER' && 'text-align: left;'}
  }
  ${And} {
    margin: 0 ${props => props.theme.SPACING.S32};
    ${({ textAlignment }) => (textAlignment === 'CENTER' ? 'flex-grow: 0;' : '')};
  }
  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    flex-direction: column;
    align-items: ${({ textAlignment }) => mapTextAlignmentToFlexAlignItemsProperty(textAlignment)};
    width: 100%;
    ${Name1}, ${Name2} {
      align-items: ${({ textAlignment }) =>
        mapTextAlignmentToFlexAlignItemsProperty(textAlignment)};
    }
    ${And} {
      margin: ${props => props.theme.SPACING.MD} 0;
    }
  }
`;

export const ContentContainerVertical = styled.div<{
  textAlignment: string;
}>`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  text-align: ${({ textAlignment }) => textAlignment.toLowerCase()};
  width: 100%;
  ${Name1}, ${Name2} {
    flex-direction: 'row';
    justify-content: ${({ textAlignment }) =>
      mapTextAlignmentToFlexAlignItemsProperty(textAlignment)};
  }

  ${And} {
    margin: ${props => props.theme.SPACING.S32} 0;
  }
`;
