import React, { useContext, useRef } from 'react';
import dynamic from 'next/dynamic';
import InnerContainer from 'components/publicWebsiteV2/common/InnerContainer';
import type { WCmsHomepageView } from '@zola/svc-web-api-ts-client';
import { renderAbsoluteAssets } from 'components/publicWebsiteV2/util/renderHelpers';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { DeviceContext } from 'contexts/DeviceContext';
import InViewWrapper from 'components/common/InViewWrapper/InViewWrapper';

import useCroppedBottomAbsoluteAssets from 'components/publicWebsiteV2/util/useCroppedBottomAbsoluteAssets';
import { useContainerDimensions } from 'lib/hooks/useContainerDimensions';

import Hero from '../Hero';
import Home from '../Home';
import { HomeContentContainer } from './HomeContainer.styles';

const Footer = dynamic(() => import('../../../common/Footer'), {
  ssr: false,
});

const HomeContainer = ({ pageData }: { pageData?: WCmsHomepageView }): JSX.Element => {
  const {
    state: {
      wedding,
      components: { backgroundColors },
      inPreview,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { header_image_url, title } = pageData || {};
  const bodyContentRef = useRef<HTMLDivElement>(null);
  const scrollDownToBodyContent = () => {
    bodyContentRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const { absolute_assets, option_type_values, background, in_flow_assets } =
    wedding?.public_theme_v2?.components?.CONTENT_CONTAINER_HOME || {};

  const { containerRef, elementsRef, croppedDimensions } = useCroppedBottomAbsoluteAssets(
    absolute_assets,
    inPreview
  );

  const textAlignment = option_type_values?.TEXT_ALIGNMENT?.value || 'CENTER';

  const { width: containerWidth } = useContainerDimensions(containerRef);

  return (
    <>
      <Hero title={title} imageUrl={header_image_url} onDownCaretClick={scrollDownToBodyContent} />
      <HomeContentContainer
        background={background}
        containerWidth={containerWidth}
        device={device}
        inFlowAssets={in_flow_assets}
        ref={containerRef}
      >
        {renderAbsoluteAssets({
          a: absolute_assets,
          elementsRef,
          croppedDimensions,
          containerWidth,
          device,
        })}
        <InnerContainer>
          <Home pageData={pageData} ref={bodyContentRef} textAlignment={textAlignment} />
          {inPreview ? (
            <Footer showDarkMode={isDarkColor(backgroundColors.homepageContent)} />
          ) : (
            <InViewWrapper>
              <Footer showDarkMode={isDarkColor(backgroundColors.homepageContent)} />
            </InViewWrapper>
          )}
        </InnerContainer>
      </HomeContentContainer>
    </>
  );
};

export default HomeContainer;
