import React, { useContext, useRef } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { mapFontValuesToSerializedStyles } from 'components/publicWebsiteV2/util/mappers';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from 'lib/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { renderAbsoluteAssets } from '../../util/renderHelpers';
import {
  OuterContainer,
  Container,
  ContentContainerVertical,
  ContentContainerHorizontal,
  Name1,
  Name2,
  And,
} from './NamesModule.styles';

type NamesModulesProps = {
  ownerFirstName?: string;
  ownerLastName?: string;
  partnerFirstName?: string;
  partnerLastName?: string;
  className?: string;
  textAlignment: string;
  hideNames?: boolean;
};

/**
 *
 * Used on
 * - passcode page
 * - single page home section
 * - multi page home section
 */
const NamesModule = ({
  ownerFirstName,
  ownerLastName,
  partnerFirstName,
  partnerLastName,
  className,
  textAlignment,
  hideNames,
}: NamesModulesProps): JSX.Element => {
  const {
    state: {
      components: { globalHeaderFontValues, globalBodyFontValues },
      wedding,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { MEDIA_QUERY } = useTheme();

  const namesComponent = wedding?.public_theme_v2?.components?.COUPLES_NAMES;
  const finalHeaderFont = mapFontValuesToSerializedStyles({
    fallback: globalHeaderFontValues,
    partial: namesComponent?.fonts?.HEADER?.[1],
    responsiveFontSize: true,
    mediaQuery: MEDIA_QUERY,
  });
  const finalBodyFont = mapFontValuesToSerializedStyles({
    fallback: globalBodyFontValues,
    partial: namesComponent?.fonts?.BODY?.[1],
    mediaQuery: MEDIA_QUERY,
  });

  const { ORIENTATION, SHOW_LAST_NAME, AND_TEXT, COUPLES_NAMES_ORDER } =
    namesComponent?.option_type_values || {};
  const orientation = ORIENTATION?.value || 'VERTICAL';
  const showLastName = SHOW_LAST_NAME?.value === 'TRUE';
  const articleText = AND_TEXT?.value === '&' ? '&' : 'and';
  const H2 = styled.h2`
    ${finalHeaderFont}
  `;
  const renderName = (firstName?: string, lastName?: string) => {
    if (!firstName) return null;
    if (!showLastName) return <H2>{firstName}</H2>;
    if (orientation === 'VERTICAL') {
      return (
        <H2>
          {firstName} {lastName}
        </H2>
      );
    }
    return (
      <>
        <H2>{firstName}</H2>
        <H2>{lastName}</H2>
      </>
    );
  };

  const OwnerName = renderName(ownerFirstName, ownerLastName);
  const PartnerName = renderName(partnerFirstName, partnerLastName);

  const Name1Content = COUPLES_NAMES_ORDER?.value === 'PARTNER_FIRST' ? PartnerName : OwnerName;
  const Name2Content = COUPLES_NAMES_ORDER?.value === 'PARTNER_FIRST' ? OwnerName : PartnerName;

  const ContentContainer =
    orientation === 'VERTICAL' ? ContentContainerVertical : ContentContainerHorizontal;

  const containerRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(containerRef);

  return (
    <OuterContainer hideNames={hideNames}>
      <Container
        className={className}
        inFlowAsset={namesComponent?.in_flow_assets}
        ref={containerRef}
      >
        {renderAbsoluteAssets({ a: namesComponent?.absolute_assets, containerWidth, device })}
        <ContentContainer textAlignment={textAlignment}>
          <Name1>{Name1Content}</Name1>
          <And css={finalBodyFont}>{articleText}</And>
          <Name2>{Name2Content}</Name2>
        </ContentContainer>
      </Container>
    </OuterContainer>
  );
};

export default NamesModule;
