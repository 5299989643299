import styled from '@emotion/styled';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import { mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import { Device } from 'lib/universal/utils/devices';

export const Container = styled.div<{
  containerWidth?: number;
  device?: Device;
  inFlowAsset?: WInFlowThemeComponentAssetSummaryView;
}>`
  margin-top: ${({ theme }) => theme.SPACING.XL};
  padding-bottom: ${({ theme }) => theme.SPACING.XXL};
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding-bottom: ${({ theme }) => theme.SPACING.LG};
  }
  position: relative;

  ${({ inFlowAsset, device, containerWidth }) =>
    mapInFlowAssetToCSS(inFlowAsset, device, containerWidth)}

  > div:not(:first-of-type) {
    margin-top: 100px;
  }
`;
