import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
`;

export const HorizontalHeader = styled.h3`
  margin-bottom: 12px;
`;

export const HorizontalContainer = styled.div<{ hasTwoElements?: boolean; textAlignment: string }>`
  display: flex;

  align-items: ${({ textAlignment }) => (textAlignment === 'CENTER' ? 'center' : 'flex-start')};
  > div {
    width: ${({ hasTwoElements }) => (hasTwoElements ? '50%' : '100%')};
    ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
      width: 100%;
    }
    text-align: ${({ textAlignment }) => textAlignment.toLowerCase()};
  }

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    flex-direction: column;
  }
`;

export const To = styled.div`
  margin: ${({ theme }) => theme.SPACING.MD} 0;
`;

export const VerticalContainer = styled.div<{ textAlignment: string }>`
  text-align: ${({ textAlignment }) => textAlignment.toLowerCase()};
`;

export const VerticalLocationContainer = styled.div`
  margin-top: ${({ theme }) => theme.SPACING.S24};
`;
