import React, { useContext, useRef } from 'react';
import type { WCmsHomepageSectionView } from '@zola/svc-web-api-ts-client';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from 'lib/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import { Container, Subtitle, Subcontainer, TitleContainer, Divider } from './Story.styles';

type StoryProps = Pick<WCmsHomepageSectionView, 'title' | 'subtitle' | 'description'> & {
  textAlignment: string;
  orientation: string;
};

const Story = ({
  title,
  subtitle,
  description,
  textAlignment,
  orientation,
}: StoryProps): JSX.Element => {
  const {
    state: {
      components: {
        cmsEntityComponentHeaderFontStyles,
        cmsEntityComponentBodyFontStyles,
        cmsEntityComponentBodyFontValues,
        cmsEntityComponentBodyFont2Styles,
      },
      wedding,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { HOMEPAGE_STORY_HEADER } = wedding?.public_theme_v2?.components || {};
  const titleRef = useRef(null);
  const { width: containerWidth } = useContainerDimensions(titleRef);

  return (
    <Container isHorizontalOrientation={orientation === 'HORIZONTAL'} textAlignment={textAlignment}>
      <TitleContainer
        inFlowAsset={HOMEPAGE_STORY_HEADER?.in_flow_assets}
        device={device}
        containerWidth={containerWidth}
        ref={titleRef}
      >
        <div>
          <h3 css={cmsEntityComponentHeaderFontStyles}>{title}</h3>
          <Subtitle css={cmsEntityComponentBodyFont2Styles}>{subtitle}</Subtitle>
        </div>
      </TitleContainer>
      {orientation === 'HORIZONTAL' && <Divider color={cmsEntityComponentBodyFontValues.color} />}
      <Subcontainer>
        <p css={cmsEntityComponentBodyFontStyles}>{description}</p>
      </Subcontainer>
    </Container>
  );
};

export default Story;
