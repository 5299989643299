import styled from '@emotion/styled';
import { CaretIcon } from '@zola/zola-ui/src/components/SvgIcons';
import { PartialComponentFontView } from 'components/publicWebsiteV2/util/mappers';
import {
  Z_INDEX_MAP,
  Z_INDEX_HERO_IMAGE_KEY,
  Z_INDEX_TEXT_LAYER_KEY,
} from '../../../util/zIndexConstants';

export const Title = styled.h1<{
  fadeHeroMsg?: boolean;
  publicHomeAnimations?: boolean;
  inPreview?: boolean;
}>`
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  opacity: 0;
  transition: opacity 0.5s ease-in;

  ${({ fadeHeroMsg, publicHomeAnimations, inPreview }) =>
    publicHomeAnimations && !inPreview && fadeHeroMsg && `opacity: 1;`}
  ${({ publicHomeAnimations, inPreview }) =>
    publicHomeAnimations &&
    inPreview &&
    `
  opacity: 1;
  transition: unset;
`}
  ${({ publicHomeAnimations }) =>
    !publicHomeAnimations &&
    `
    opacity: 1;
    transition: unset;
  `}

  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    font-size: 52px;
  }
`;

export const getHeroTitleOverrideStyles = (
  hasBackgroundImage: boolean
): PartialComponentFontView => {
  if (hasBackgroundImage) {
    return { color: 'FFFFFF' };
  }
  return {};
};

export const TitleContainer = styled.div`
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  margin: ${props => props.theme.SPACING.LG} ${props => props.theme.SPACING.XL};

  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    margin: ${props => props.theme.SPACING.XL} ${props => props.theme.SPACING.MD};
  }
`;

export const HeroImage = styled.div<{ backgroundImage?: string }>`
  ${({ backgroundImage }) =>
    backgroundImage
      ? `background-image: url(${backgroundImage}); background-size: cover; background-position: center;`
      : ''}
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX_MAP[Z_INDEX_HERO_IMAGE_KEY]};
`;

export const MobileScrollDownCaret = styled(CaretIcon)<{ color?: string }>`
  display: none;
  ${props => props.theme.MEDIA_QUERY.MOBILE} {
    display: block;
  }
  color: #${({ color }) => color};
  z-index: ${Z_INDEX_MAP[Z_INDEX_TEXT_LAYER_KEY]};
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  bottom: 40px;
`;
