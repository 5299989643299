import React, { useContext, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import type {
  WCmsHomepageView,
  WPublicNavItemView,
  WCmsVideoView,
} from '@zola/svc-web-api-ts-client';
import VideoSnippet from 'components/util/VideoSnippet';
import { DeviceContext } from 'contexts/DeviceContext';
import { useContainerDimensions } from 'lib/hooks/useContainerDimensions';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import NamesModule from '../../common/NamesModule';
import StorySection from './StorySection';
import {
  HomepageDividerFlourish,
  PaddedContentContainer,
  Hashtag,
  StyledLocationDateModule,
  StyledDateCountdown,
  RSVPButton,
  RSVPButtonContainer,
  StyledFooterImage,
} from './Home.styles';
import getPublicWebsiteHref from '../../util/getPublicWebsiteHref';
import { stripTimeStamp } from './DateCountdown/helpers';

type HomePageType = WCmsHomepageView & {
  homepage_video?: WCmsVideoView[];
};

type HomeProps = {
  pageData?: HomePageType;
  textAlignment?: string;
  /** Used on single page; doesn't render names and adds top padding on desktop */
  hideNames?: boolean;
};

const Home = React.forwardRef(
  (
    { pageData, textAlignment = 'CENTER', hideNames }: HomeProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const { footer_image_url, homepage_sections: entities, video } = pageData || {};
    const {
      state: {
        components: { Button, globalBodyFontStyle },
        wedding,
      },
    } = useWebsiteThemeContext();
    const { device } = useContext(DeviceContext);
    const { nav_items = [], slug, public_theme_v2 } = wedding || {};
    const showRSVP =
      nav_items.filter(n => n.type === (('RSVP' as unknown) as WPublicNavItemView.TypeEnum))
        .length > 0;
    const homepageDividerComponent = public_theme_v2?.components?.HOMEPAGE_DIVIDER;

    const router = useRouter();

    const dividerRef = useRef(null);
    const { width: containerWidth } = useContainerDimensions(dividerRef);

    const {
      owner_first_name,
      owner_last_name,
      partner_first_name,
      partner_last_name,
      hashtag,
      wedding_date_range_start,
      wedding_date,
    } = wedding || {};

    const showCustomDateMessage = Boolean(wedding?.custom_date_message);
    const weddingCountdownHidden = Boolean(wedding?.wedding_countdown_hidden);

    return (
      <>
        <PaddedContentContainer
          ref={ref}
          textAlignment={textAlignment}
          addTopPaddingOnDesktop={hideNames}
        >
          <NamesModule
            ownerFirstName={owner_first_name}
            ownerLastName={owner_last_name}
            partnerFirstName={partner_first_name}
            partnerLastName={partner_last_name}
            textAlignment={textAlignment}
            hideNames={hideNames}
          />
          {hashtag && <Hashtag css={globalBodyFontStyle}>#{hashtag}</Hashtag>}
          <StyledLocationDateModule textAlignment={textAlignment} />
          {wedding_date && !showCustomDateMessage && !weddingCountdownHidden && (
            <div data-testid="WeddingCountdown">
              <StyledDateCountdown
                startDate={stripTimeStamp(wedding_date_range_start || wedding_date)}
              />
            </div>
          )}
          {showRSVP && (
            <RSVPButtonContainer>
              <Link href={getPublicWebsiteHref(slug, 'rsvp', router?.query)}>
                <RSVPButton as={Button}>RSVP</RSVPButton>
              </Link>
            </RSVPButtonContainer>
          )}
          {showRSVP && (entities || []).length > 0 && (
            <HomepageDividerFlourish
              containerWidth={containerWidth}
              device={device}
              inFlowAsset={homepageDividerComponent?.in_flow_assets}
              ref={dividerRef}
            />
          )}
          {video && (
            <VideoSnippet
              url={video.url}
              caption={video.caption}
              id={video.id}
              key={video.id}
              additionalPaddingBottom={!entities?.length}
              textAlignment={textAlignment}
            />
          )}
          <StorySection homepageSections={entities} video={video} textAlignment={textAlignment} />
        </PaddedContentContainer>
        <StyledFooterImage imageUrl={footer_image_url} />
      </>
    );
  }
);

export default Home;
