import styled from '@emotion/styled';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';

export const OuterContainer = styled.div<{ paddingBottom?: boolean }>`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 48px;
  padding-bottom: 48px;
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: 150px;`};
`;
export const VideoContainer = styled.div<{ nonResponsive?: boolean }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  ${({ nonResponsive }) =>
    nonResponsive &&
    `
      // doesn't need to be responsive inPreview
      width: 800px;
      height: 450px;
      padding-top: unset;
  `}
`;

export const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const Caption = styled.p<{ textAlignment: string }>`
  margin-top: ${SPACING.SM};
  text-align: ${({ textAlignment }) => textAlignment};
  margin-bottom: 0;
`;
