import styled from '@emotion/styled';
import { mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import { Device } from 'lib/universal/utils/devices';

export const Subtitle = styled.div`
  margin-top: ${({ theme }) => theme.SPACING.SM};
  margin-bottom: ${({ theme }) => theme.SPACING.S24};
`;

export const Subcontainer = styled.div`
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    width: 100%;
    padding: 0;
  }
  p {
    white-space: pre-wrap;
  }
`;

export const TitleContainer = styled(Subcontainer)<{
  containerWidth?: number;
  device?: Device;
  inFlowAsset?: WInFlowThemeComponentAssetSummaryView;
}>`
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    border: none;
  }
  > div {
    display: inline-flex;
    flex-direction: column;
  }
  ${({ inFlowAsset, device, containerWidth }) =>
    mapInFlowAssetToCSS(inFlowAsset, device, containerWidth)}
`;

export const Container = styled.div<{
  isHorizontalOrientation?: boolean;
  textAlignment: string;
}>`
  overflow-wrap: anywhere;
  position: relative;
  display: flex;
  flex-direction: ${({ isHorizontalOrientation }) => (isHorizontalOrientation ? 'row' : 'column')};
  align-items: ${({ textAlignment }) => (textAlignment === 'CENTER' ? 'center' : 'flex-start')};
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    flex-direction: column;
  }
  ${({ isHorizontalOrientation, theme }) =>
    isHorizontalOrientation &&
    `
${Subcontainer}, ${TitleContainer} {
    width: 50%;
    padding: 32px;

    ${theme.MEDIA_QUERY.MOBILE} {
      width: 100%;
      padding: 0;
    }
}
`}
`;

export const Divider = styled.div<{ color?: string }>`
  position: absolute;
  height: 100%;
  left: 50%;
  width: 1px;
  background-color: #${({ color }) => color};

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    display: none;
  }
`;
