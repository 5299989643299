import styled from '@emotion/styled';
import type {
  WThemeComponentBackgroundView,
  WInFlowThemeComponentAssetSummaryView,
} from '@zola/svc-web-api-ts-client';
import { mapBackgroundToCSS, mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import { Device } from 'lib/universal/utils/devices';

export const HomeContentContainer = styled.div<{
  background?: WThemeComponentBackgroundView;
  containerWidth?: number;
  device?: Device;
  inFlowAssets?: WInFlowThemeComponentAssetSummaryView;
}>`
  position: relative;
  overflow: visible;
  padding-top: 1px; // prevents margin from collapsing: https://stackoverflow.com/questions/1762539/margin-on-child-element-moves-parent-element
  margin-top: -1px;
  ${({ background }) => mapBackgroundToCSS(background)}
  ${({ inFlowAssets, device, containerWidth }) =>
    mapInFlowAssetToCSS(inFlowAssets, device, containerWidth)}
`;
