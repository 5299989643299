import React, { useContext, useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { DeviceContext } from 'contexts/DeviceContext';

import { motion, useViewportScroll, useTransform } from 'framer-motion';
import useRefScrollProgress from 'lib/hooks/useRefScrollProgress';
import featureFlags from 'util/featureFlags';
import { useContainerDimensions } from 'lib/hooks/useContainerDimensions';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import useImageDimensions from 'paper/hooks/imageDimensions';

import { renderAbsoluteAssets } from '../../../util/renderHelpers';
import { PartialComponentFontView, mapFontValuesToSerializedStyles } from '../../../util/mappers';

import { DESKTOP_HEIGHT, MOBILE_HEIGHT, getHeroImageSizeParams } from './utils';

import {
  getHeroTitleOverrideStyles,
  HeroImage,
  MobileScrollDownCaret,
  TitleContainer,
  Title,
} from './Hero.styles';

const Hero = ({
  title,
  imageUrl,
  className,
  onDownCaretClick,
}: {
  title?: string;
  imageUrl?: string;
  className?: string;
  onDownCaretClick?: () => void;
}): JSX.Element | null => {
  const {
    state: {
      components: { globalHeaderFontValues },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();
  const { device } = useContext(DeviceContext);
  const { MEDIA_QUERY } = useTheme();

  const [fadeHeroMsg, setFadeHeroMsg] = useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [heroImageSizedUrl, setHeroImageSizedUrl] = useState<string>();

  const { absolute_assets: heroAbsoluteAssets, fonts } =
    wedding?.public_theme_v2?.components?.HERO_HOME || {};
  const heroHeaderFontStyles = fonts?.HEADER?.[1];
  const heroTitleStyles: PartialComponentFontView = {
    ...heroHeaderFontStyles,
    ...getHeroTitleOverrideStyles(Boolean(imageUrl)),
  };
  const heroTitleStylesFinal = mapFontValuesToSerializedStyles({
    fallback: globalHeaderFontValues,
    partial: heroTitleStyles,
    responsiveFontSize: true,
    mediaQuery: MEDIA_QUERY,
  });

  const { ref: heroWrapperRef, end: heroWrapperEnd } = useRefScrollProgress();
  const { scrollYProgress } = useViewportScroll();
  const scrollTransform = useTransform(
    scrollYProgress,
    [0, heroWrapperEnd],
    [0, -(device?.isDesktop() ? DESKTOP_HEIGHT : MOBILE_HEIGHT)]
  );
  const transformObj = {
    top: scrollTransform,
    marginBottom: scrollTransform,
    transition: 'top 0.3s ease-out, margin-bottom 0.3s ease-out',
  };
  const containerDimensions = useContainerDimensions(heroWrapperRef);
  const { width: containerWidth } = containerDimensions;
  const imageDimensions = useImageDimensions(imageUrl ? `${imageUrl}?w=10` : undefined);
  const { width: imageWidth } = imageDimensions;

  useEffect(() => {
    setHasMounted(true);
    setFadeHeroMsg(true);
  }, []);

  useEffect(() => {
    if (featureFlags.get('websiteV2MobileImgs')) {
      // TO FIX: There is a bug in preview where HomeContainer gets unmounted many times, causing the hero image to reset and flash
      if (
        document &&
        containerDimensions.width > 0 &&
        (imageDimensions?.width || 0) > 0 &&
        !inPreview &&
        imageUrl
      ) {
        const img = document.createElement('img');
        const imageSizedUrl = `${imageUrl}?${getHeroImageSizeParams(
          containerDimensions,
          imageDimensions,
          device
        )}`;
        img.src = imageSizedUrl;

        img.onload = (): void => {
          setHeroImageSizedUrl(imageSizedUrl);
        };
      }
    } else {
      const imageSizedUrl = `${imageUrl}?h=${
        (device?.notMobile() ? DESKTOP_HEIGHT : MOBILE_HEIGHT) * 2
      }`;
      setHeroImageSizedUrl(imageSizedUrl);
    }
  }, [containerWidth, imageWidth]);

  const heroContainerStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    overflow-y: hidden;

    ${Boolean(imageUrl) &&
    `
    min-height: ${DESKTOP_HEIGHT}px;

    ${MEDIA_QUERY.MOBILE} {
      min-height: ${MOBILE_HEIGHT}px;
    }

    ${Title} {
      filter: drop-shadow(0px 6px 10px rgba(0,0,0,.2));
    }
    `}
  `;

  if (!title && !imageUrl) return null;

  return (
    <motion.div
      className={className}
      css={heroContainerStyles}
      style={
        featureFlags.get('publicHomeAnimations') && hasMounted && !inPreview
          ? transformObj
          : undefined
      }
      ref={heroWrapperRef}
    >
      <HeroImage backgroundImage={heroImageSizedUrl || imageUrl} />
      <TitleContainer>
        <Title
          css={heroTitleStylesFinal}
          fadeHeroMsg={fadeHeroMsg}
          publicHomeAnimations={featureFlags.get('publicHomeAnimations')}
          inPreview={inPreview}
        >
          {title}
        </Title>
      </TitleContainer>
      <MobileScrollDownCaret width={28} color={heroTitleStyles.color} onClick={onDownCaretClick} />
      {renderAbsoluteAssets({ a: heroAbsoluteAssets, containerWidth, device })}
    </motion.div>
  );
};

export default Hero;
