import React, { useContext } from 'react';
import { DeviceContext } from 'contexts/DeviceContext';
import styled from '@emotion/styled';

type FooterImageProps = {
  imageUrl?: string;
  className?: string;
};

const FooterImage = ({ imageUrl, className }: FooterImageProps): JSX.Element | null => {
  const Image = styled.img`
    width: 100%;
  `;
  const { device } = useContext(DeviceContext);

  if (!imageUrl) return null;
  // TODO: ADJUST FOR SINGLE PAGE
  const DESKTOP_WIDTH = 956;
  const MOBILE_WIDTH = 703;
  const sizedImageUrl = `${imageUrl}?w=${(device?.notMobile() ? DESKTOP_WIDTH : MOBILE_WIDTH) * 2}`;

  return (
    <div className={className}>
      <Image src={sizedImageUrl} />
    </div>
  );
};

export default FooterImage;
