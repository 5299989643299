import styled from '@emotion/styled';
import { mapInFlowAssetToCSS } from 'components/publicWebsiteV2/util/mappers';
import type { WInFlowThemeComponentAssetSummaryView } from '@zola/svc-web-api-ts-client';
import { Device } from 'lib/universal/utils/devices';
import { NON_ADJUSTED_RANGE } from 'components/publicWebsiteV2/pageLayouts/SinglePageLayout/transformMediaQuery';
import LocationDateModule from './LocationDateModule';
import DateCountdown from './DateCountdown';
import FooterImage from './FooterImage';

export const HomepageDividerFlourish = styled.div<{
  inFlowAsset?: WInFlowThemeComponentAssetSummaryView;
  device?: Device;
  containerWidth?: number;
}>`
  margin-bottom: ${({ theme }) => theme.SPACING.XL};
  ${({ inFlowAsset, device, containerWidth }) =>
    mapInFlowAssetToCSS(inFlowAsset, device, containerWidth)}
`;

export const PaddedContentContainer = styled.div<{
  textAlignment: string;
  addTopPaddingOnDesktop?: boolean;
}>`
  text-align: ${({ textAlignment }) => textAlignment.toLowerCase()};
  ${({ addTopPaddingOnDesktop, theme }) =>
    addTopPaddingOnDesktop
      ? `padding-top: ${theme.SPACING.XL}; ${NON_ADJUSTED_RANGE} {padding-top: 0px;}`
      : ''}
  // Add padding to children so assets can be decorated to children components at full width
  & > * {
    padding-left: ${({ theme }) => theme.SPACING.S32};
    padding-right: ${({ theme }) => theme.SPACING.S32};
  }
`;

export const Hashtag = styled.div`
  margin-bottom: ${({ theme }) => theme.SPACING.LG};
`;

export const StyledLocationDateModule = styled(LocationDateModule)`
  margin-top: ${({ theme }) => theme.SPACING.LG};
  margin-bottom: ${({ theme }) => theme.SPACING.LG};
`;

export const StyledDateCountdown = styled(DateCountdown)`
  margin-top: ${({ theme }) => theme.SPACING.LG};
  margin-bottom: ${({ theme }) => theme.SPACING.XL};
`;

export const RSVPButton = styled.button`
  width: 300px;
  ${({ theme }) => theme.MEDIA_QUERY.MOBILE} {
    width: 200px;
  }

  ${({ theme }) => theme.MEDIA_QUERY.MOBILE_XS} {
    width: 100%;
  }
`;

export const RSVPButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.SPACING.XL};
`;

export const StyledFooterImage = styled(FooterImage)`
  margin-top: ${({ theme }) => theme.SPACING.XL};
`;
